import React, { Component, Suspense } from 'react';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

const Survey = React.lazy(() => import('./components/survey.component'));

class App extends Component {

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    if (window.location.pathname === "/") {
      // redirect to main domain
      window.location.href = "https://"+window.location.hostname.substring(window.location.hostname.lastIndexOf(".", window.location.hostname.lastIndexOf(".") - 1) + 1)
    }
  }

  render() {

    return (<Router>
      <Suspense fallback={<div>Loading...</div>}>
      <div className="App">
        <div className="container-fluid wrapper-content">
          <Routes>
            <Route path="/:id" element={<Survey />} />
          </Routes>
        </div>
      </div>
      </Suspense>
      </Router>
    );
  }
}

export default App;
